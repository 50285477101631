<template>
  <div class="" style="width: 100%; margin-bottom: 100px;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn fab x-small @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="d-flex flex-column">
            <h1 style="line-height: 26px; font-size: 26px; text-align: left;">{{data.name || "Product"}}</h1>
            <span>Created By: {{ lookupUsername(data.createdBy) }} at {{ utils.formatDate(data.createdAt) }}</span>
          </span>
          <span class="ml-5 d-flex flex-row align-center">
            <v-btn v-if="isAllowed('product', 'd')" fab x-small color="error" @click="openDeleteProductDialog(data.id)" :loading="loader"><v-icon>mdi-delete</v-icon></v-btn>
            <v-btn v-if="data.type==='simple'" class="ml-2" fab x-small color="info" @click="$router.push(`/logs/producthistory/${$route.params.id}`)"><v-icon>mdi-history</v-icon></v-btn>
            <v-btn v-if="getGlobalValue('productSerialShowOnProducts') && data.type==='simple'" class="ml-2" fab x-small color="info" :loading="loadingSerials" @click="viewSerials"><v-icon>mdi-barcode</v-icon></v-btn>
            <v-btn v-if="getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true'" class="ml-2" fab x-small color="info" @click="printDirect('productLabel', {id: data.id})"><v-icon>mdi-printer</v-icon></v-btn>
          </span>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <span>
                <span class="d-flex flex-row align-center mb-1">
                  <h2>General</h2>
                  <span class="ml-1 align-center">
                    <v-btn :loading="loaders.general" @click="updateGeneral" v-if="isAllowed('product', 'u') && data.id" class="ml-2" color="success" small>Save Changes</v-btn>
                  </span>
                </span>
              </span>
              <v-form :disabled="loaders.general">
                <v-row>
                  <v-col>
                    <span class="d-flex flex-row justify-space-between align-center">
                      <v-radio-group
                          label="Type"
                          v-model="data.type"
                          mandatory
                          dense
                          row
                      >
                        <v-radio
                            :key=i v-for="(type, i) in productTypeValues"
                            :label="type.name"
                            :value="type.value"
                            :disabled="type.disabled"
                        />
                      </v-radio-group>
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-n5">
                  <v-col cols="3">
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        dense
                        label="Name"
                        outlined
                        clearable
                        v-model="data.name"
                        @change="updateVariationNames"
                    />
                    <v-text-field
                        dense
                        label="SKU (Model Number)"
                        outlined
                        clearable
                        v-if="data.type==='simple'"
                        style="margin-top: -20px;"
                        v-model="data.sku"
                        @focus="lockScanBusForSKUBC"
                        @blur="unlockScanBusForSKUBC"
                        @change="trimBC(data)"
                    />
                    <!--                    <v-text-field -->
                    <!--                      @focus="lockGlobalQueryBc" -->
                    <!--                      @blur="unlockGlobalQueryBc"-->
                    <!--                      dense-->
                    <!--                      label="Barcode"-->
                    <!--                      outlined-->
                    <!--                      v-if="data.type==='simple'"-->
                    <!--                      v-model="data.barcode"-->
                    <!--                    />-->
                    <v-textarea
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        label="Description"
                        outlined
                        clearable
                        rows="6"
                        v-model="data.description"
                    />
                    <v-text-field v-if="data.metadata && data.type==='simple'" v-model="data.metadata.packagingQuantity" type="number" value="1" outlined dense label="Packaging Quantity"/>
                    <v-checkbox v-if="data.type==='simple'" :label="`Require ${serialNaming.plural}?`" v-model="data.metadata.requireSerials"/>
                    <!--                    <h2>E-commerce Options</h2>-->
                    <!--                    <v-checkbox v-if="data.type==='simple'" label="Visible?" v-model="data.ecomVisible"/>-->
                  </v-col>
                  <v-col v-if="data.type==='simple'" cols="3">
                    <span class="d-flex flex-row">
                      <v-text-field
                          @focus="lockGlobalQueryBc"
                          @blur="unlockGlobalQueryBc"
                          dense
                          label="Regular Price"
                          outlined
                          clearable
                          type="number"
                          v-if="data.type==='simple'"
                          v-model="data.regularPrice"
                      />
                      <span v-if="data.taxType !== 1" class="d-flex flex-row align-baseline">
                        <v-btn class="ml-3" x-small fab color="info" @click="addVat('rp', -1)">+VAT</v-btn>
                        <v-btn class="ml-2" x-small fab color="info" @click="removeVat('rp', -1)">-VAT</v-btn>
                      </span>
                    </span>
                    <span class="d-flex flex-row align-baseline">
                      <v-text-field
                          @focus="lockGlobalQueryBc"
                          @blur="unlockGlobalQueryBc"
                          dense
                          label="Alt Price"
                          outlined
                          clearable
                          style="margin-top: -20px;"
                          type="number"
                          v-if="data.type==='simple'"
                          v-model="data.salePrice"
                      />
                      <span v-if="data.taxType !== 1" class="d-flex flex-row align-baseline">
                        <v-btn class="ml-3" x-small fab color="info" @click="addVat('pp', -1)">+VAT</v-btn>
                        <v-btn class="ml-2" x-small fab color="info" @click="removeVat('pp', -1)">-VAT</v-btn>
                      </span>
                    </span>

                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        dense
                        label="Blue Tag Price"
                        outlined
                        clearable
                        style="margin-top: -20px;"
                        type="number"
                        v-if="data.type==='simple' && getGlobalValue('VEC_INCLUDES_BLUETAGPRICE')==='true'"
                        v-model="data.metadata.blueTagPrice"
                    />
                    <span v-if="getGlobalValue('productTaxTypeEditable')==='true' && getGlobalValue('productTaxTypeMinimal')==='true' && data.type==='simple'">
                      <span>Tax Type</span>
                      <v-switch
                          class="mt-0"
                          v-model="data.taxType"
                          color="info"
                          :label="data.taxType===1?'Non-VAT':'VAT Inclusive'"
                          :false-value=1
                          :true-value=2
                      />
                    </span>
                    <span v-else-if="getGlobalValue('productTaxTypeEditable')==='true' && data.type==='simple'">
                      <v-select hide-details label="Tax Type" item-text="name" item-value="id" outlined v-model="data.taxType" :items="taxTypes"/>
                    </span>
                    <span v-else-if="getGlobalValue('productTaxTypeEditable')!=='true'">
                      <v-select disabled hide-details label="Tax Type" item-text="name" item-value="id" outlined v-model="data.taxType" :items="taxTypes"/>
                    </span>
                    <span v-if="data.type==='simple' && getGlobalValue('VEC_ADMIN_USE_FOREIGN_CURRENCY')==='true'">
                      <span class="d-flex flex-row align-center pt-4 pb-2">
                        <span>Foreign Currencies Regular Price</span>
                        <v-btn x-small class="ml-2" color="info" @click="addCurrency(data)"><v-icon>mdi-plus</v-icon> New</v-btn>
                      </span>
                      <span v-if="data.metadata?.currencyPrices">
                        <span v-for="(currency, c) of data.metadata.currencyPrices" :key="c">
                          <span class="d-flex flex-row align-center pb-1" style="border-bottom: 1px solid #eee;">
                            <v-text-field outlined dense class="pr-1" hide-details label="Code (USD, JMD, etc)" v-model="currency.code"/>
                            <v-text-field outlined dense class="pl-1" hide-details label="Value" number v-model="currency.value"/>
<!--                            <v-checkbox dense hide-details label="VAT?" v-model="currency.useVat"/>-->
                            <v-btn x-small class="ml-1" color="error" @click="removeCurrency(data, c)" text><v-icon>mdi-close</v-icon></v-btn>
                          </span>
                        </span>
                      </span>
                    </span>
                  </v-col>
                  <v-col cols="6" v-if="data.type==='simple'">
                    <v-card outlined>
                      <v-card-title>Inventory</v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col v-if="data.type==='simple'">
                            <v-checkbox
                                v-if="data.type==='simple'"
                                v-model="data.manageStock"
                                label="Manage Stock?"
                                outlined
                                dense
                                hint="Enable this to sell products from inventory quantities."
                                persistent-hint
                            />
                            <v-checkbox
                                v-if="data.type==='simple'"
                                v-model="data.notifyOnMinQuantity"
                                label="Notify On Min Quantity?"
                                outlined
                                dense
                                hint="Uncheck to disable notifications when this product reaches it's minimum quantity."
                                persistent-hint
                            />
                            <v-text-field
                                @focus="lockGlobalQueryBc"
                                @blur="unlockGlobalQueryBc"
                                dense
                                class="mt-2"
                                label="Minimum Notification Quantity"
                                outlined
                                clearable
                                type="number"
                                :disabled="!data.notifyOnMinQuantity"
                                v-if="data.type==='simple'"
                                v-model="data.minQuantity"
                            />
                            <v-text-field
                                @focus="lockGlobalQueryBc"
                                @blur="unlockGlobalQueryBc"
                                dense
                                style="margin-top: -10px;"
                                label="Available Quantity"
                                outlined
                                clearable
                                type="number"
                                v-if="data.type==='simple'"
                                v-model="data.availableQty"
                            />
                            <v-text-field
                                @focus="lockGlobalQueryBc"
                                @blur="unlockGlobalQueryBc"
                                dense
                                label="In Stock Quantity"
                                outlined
                                clearable
                                type="number"
                                style="margin-top: -20px;"
                                v-if="data.type==='simple'"
                                v-model="data.physicalQty"
                            />
                          </v-col>
                          <v-col>
                            <v-text-field
                                @focus="lockGlobalQueryBc"
                                @blur="unlockGlobalQueryBc"
                                dense
                                class="mt-2"
                                label="Minimum Order Quantity"
                                hide-details
                                outlined
                                clearable
                                type="number"
                                v-if="data.type==='simple'"
                                v-model="data.metadata.minSaleQuantity"
                            />
                            <v-text-field
                                dense
                                class="mt-2"
                                label="Enter a unit type"
                                hide-details
                                outlined
                                clearable
                                v-if="data.type==='simple'"
                                @focus="showUnits=true"
                                v-model="data.metadata.unit"
                            />
                            <v-select
                                v-if="data.type==='simple' && showUnits"
                                :items="unitTypes"
                                item-text="singular"
                                item-value="singular"
                                v-model="data.metadata.unit"
                                label="...or choose a unit from this list."
                                @blur="showUnits=false"
                                class="mt-2"
                                outlined
                                dense
                                clearable
                            />
                            <v-checkbox :label="`Allow Fractional Quantities`" v-if="data.type==='simple'" hide-details v-model="data.metadata.allowFractional"/>
                            <v-text-field
                                @focus="lockGlobalQueryBc"
                                @blur="unlockGlobalQueryBc"
                                dense
                                class="mt-2"
                                label="Factor of Quantity"
                                hide-details
                                outlined
                                clearable
                                type="number"
                                v-if="data.type==='simple' && data.metadata.allowFractional"
                                v-model="data.metadata.quantityStepAmount"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="unsavedTypeChange">
        <v-col>
          <v-card>
            <v-card-title class="red--text">You have required unsaved changes!</v-card-title>
            <v-card-text class="red--text d-flex flex-column">
              <span>You have changed the product type. You must save those changes before continuing with other changes.</span>
              <span v-if="data.type==='variable'">This will result in some of your <b>Simple</b> product data being removed.</span>
              <span v-if="data.type==='simple'">This will result in all your <b>Variation</b> data being removed.</span>
              <span>Are you sure you wish to continue? You can change it back now before pressing <b>Save Changes</b>.</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <span>
                <span class="d-flex flex-row align-center mb-1">
                  <h2>Suppliers</h2>
                  <span class="ml-1 align-center">
                    <v-btn :disabled="loaders.suppliers || unsavedTypeChange" v-if="isAllowed('supplier','c')" @click="createSupplierDialog=true" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn :disabled="loaders.suppliers || unsavedTypeChange" @click="updateSuppliers" :loading="loaders.suppliers" v-if="isAllowed('product', 'u')" class="ml-2" color="success" small>Save Changes</v-btn>
                  </span>
                </span>
                <v-form :disabled="loaders.suppliers || unsavedTypeChange">
                  <span class="d-flex flex-row">
                    <v-card class="col-4 mr-1" outlined>
                      <v-card-text style="max-height: 400px;">
                        <v-autocomplete
                            clearable
                            v-model="data.suppliers"
                            :items="suppliers"
                            item-text="name"
                            return-object
                            outlined
                            label="Suppliers"
                            chips
                            multiple
                            deletable-chips
                            @change="updateVariationSuppliers"
                        />
                      </v-card-text>
                    </v-card>
                    <v-card class="col-8 ml-1" outlined>
                      <v-card-text style="max-height: 400px; overflow-y: auto;">
                        <span v-if="data.suppliers.length===0">
                          <span>No suppliers selected.</span>
                        </span>
                        <span v-if="data.type==='variable'" class="d-flex flex-column">
                          <span>Cost prices are set on each variation for the selected suppliers.</span>
                        </span>
                        <v-card outlined v-for="(supplier, i) in data.suppliers" :key="i" class="mb-1">
                          <v-card-text class="d-flex flex-row align-center my-0 py-0">
                            <span class="col-9">{{getSupplierName(supplier.id)}}</span>
                            <v-text-field clearable v-if="isAllowed('product', 'viewEditCP')" class="mt-2" style="margin-bottom: -20px;" dense type="number" label="Cost Price" outlined v-model="supplier.costPrice"/>
                            <v-btn @click="copyCostPriceToVariations(supplier)" v-if="data.type==='variable'" x-small fab color="info" class="ml-2"><v-icon>mdi-transfer</v-icon></v-btn>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </span>
                </v-form>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-if="data.type==='simple'">
            <v-card-text>
              <span>
                <span class="d-flex flex-row align-center mb-1">
                  <h2>Attributes</h2>
                  <span class="ml-1">
                    <v-btn :disabled="loaders.attributes || unsavedTypeChange" v-if="isAllowed('product', 'u') && data.attributes && data.attributes.length>0" class="ml-2" color="success" small>Save Changes</v-btn>
                  </span>
                </span>
                <span>
                  <span>Not available for simple products yet.</span>
                </span>
              </span>
            </v-card-text>
          </v-card>
          <v-card v-if="data.type==='variable'">
            <v-card-text>
              <span v-if="getGlobalValue('VEC_SIMPLE_PRODUCTS_ONLY')!=='true'">
                <span class="d-flex flex-row align-center mb-1">
                  <h2>Variations</h2>
                  <span class="ml-1 align-center">
                    <v-btn :disabled="loaders.variations || unsavedTypeChange" v-if="isAllowed('product','c')" @click="openCreateVariationDialog" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn :disabled="loaders.variations || unsavedTypeChange" v-if="data.variations.length>0" @click="updateVariations" :loading="loaders.variations" class="ml-2" color="success" small>Save Changes</v-btn>
                    <v-btn :disabled="loaders.variations || unsavedTypeChange" class="ml-2" small color="info" @click="createAttributeDialog=true"><v-icon>mdi-plus</v-icon> Attribute</v-btn>
                    <v-btn :disabled="loaders.variations || unsavedTypeChange" class="ml-2" small color="info" @click="createTermsDialog=true"><v-icon>mdi-plus</v-icon> Term</v-btn>
                  </span>
                </span>
                <v-form :disabled="loaders.variations || unsavedTypeChange">
                  <v-row>
                    <v-col cols="6" class="mb-3">
                      <v-card outlined>
                        <v-card-title>Inventory</v-card-title>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-text-field
                                  dense
                                  class="mt-2"
                                  label="Enter a unit type"
                                  hide-details
                                  outlined
                                  clearable
                                  @focus="showUnits=true"
                                  v-model="data.metadata.unit"
                              />
                              <v-select
                                  v-if="showUnits"
                                  :items="unitTypes"
                                  item-text="singular"
                                  item-value="singular"
                                  v-model="data.metadata.unit"
                                  label="...or choose a unit from this list."
                                  @blur="showUnits=false"
                                  class="mt-2"
                                  outlined
                                  dense
                                  clearable
                              />
                              <v-checkbox :label="`Allow Fractional Quantities`" hide-details v-model="data.metadata.allowFractional"/>
                              <v-text-field
                                  @focus="lockGlobalQueryBc"
                                  @blur="unlockGlobalQueryBc"
                                  dense
                                  class="mt-2"
                                  label="Factor of Quantity"
                                  hide-details
                                  outlined
                                  clearable
                                  type="number"
                                  v-if="data.metadata.allowFractional"
                                  v-model="data.metadata.quantityStepAmount"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-card outlined v-for="(variation, v) of data.variations" :key="v" class="mb-2">
                    <v-card-title class="d-flex flex-row justify-space-between align-center">
                      <span>
                        <span class="text-h6 mr-1"><span v-if="variation.id" style="font-weight: normal; font-size: 12px;">(ID: {{variation.id}}) </span>{{variation.name || getCustomVariationName(variation)}}</span>
                        <v-chip small class="mr-1" :key="a" v-for="(attr, a) in variation.attributes">{{getAttributeName(attr.id)}}: {{attr.term ? getTermName(attr.id, attr.term) : "No Value Selected"}}</v-chip>
                        <v-btn class="ml-2" fab x-small color="info" @click="$router.push(`/logs/producthistory/${variation.id}`)"><v-icon>mdi-history</v-icon></v-btn>
                      </span>
                      <v-btn  v-if="variation.id && isAllowed('product', 'd')" fab x-small class="mx-2" color="error" style="float: right" @click="removeVariation(v)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="3" class="d-flex flex-column justify-space-between">
                          <span>
                            <span v-for="(attr, a) of variation.attributes" :key="a" class="d-flex flex-row justify-space-between align-center mb-1">
                              <span class="d-flex flex-row" style="width: 100%;">
                                <span class="d-flex flex-row align-center">
                                  <v-btn class="pa-0" color="error" text x-small fab @click="removeAttributeFromVariation(v, a)"><v-icon>mdi-delete</v-icon></v-btn>
                                  <v-btn @click="moveVariationAttributeUp(a, variation)" text x-small class="pa-0"><v-icon>mdi-transfer-up</v-icon></v-btn>
                                  <v-btn @click="moveVariationAttributeDown(a, variation)" text x-small class="pa-0"><v-icon>mdi-transfer-down</v-icon></v-btn>
                                </span>
                                <span class="d-flex flex-row mr-1" style="width: 100%;">
                                  <span class="col-6 v-btn--outlined v-btn pa-2">{{getAttributeName(attr.id)}}</span>
                                  <span class="col-6 v-btn--outlined v-btn pa-2 ml-1">{{getTermName(attr.id, attr.term)}}</span>
                                </span>
                              </span>
                            </span>
                            <span>
                              <v-btn block color="warning" @click="openAddAttributeToVariationDialog(variation)">{{ variation.attributes.length>0?'Another':'Add'}} Attribute</v-btn>
                            </span>
                          </span>
                        </v-col>
                        <v-col class="d-flex flex-column">
                          <v-row>
                            <v-col cols="8">
                              <v-card outlined class="mb-2">
                                <v-card-text>
                                  <v-row>
                                    <v-col>
                                      <v-checkbox
                                          v-model="variation.metadata.customName"
                                          label="Custom Name?"
                                          dense
                                          @change="getCustomVariationName(variation)"
                                      />
                                      <v-text-field
                                          @focus="lockGlobalQueryBc"
                                          @blur="unlockGlobalQueryBc"
                                          dense
                                          label="Name"
                                          outlined
                                          clearable
                                          v-if="variation.metadata.customName"
                                          v-model="variation.name"
                                          @change="getCustomVariationName(variation)"
                                      />
                                      <v-text-field
                                          dense
                                          label="SKU (Model Number)"
                                          outlined
                                          clearable
                                          v-model="variation.sku"
                                          @focus="lockScanBusForSKUBC"
                                          @blur="unlockScanBusForSKUBC"
                                          @change="trimBC(variation)"
                                      />
                                          <!--                    <v-text-field -->
                                          <!--                      @focus="lockGlobalQueryBc" -->
                                          <!--                      @blur="unlockGlobalQueryBc"-->
                                          <!--                      dense-->
                                          <!--                      label="Barcode"-->
                                          <!--                      outlined-->
                                          <!--                      v-if="data.type==='simple'"-->
                                          <!--                      v-model="data.barcode"-->
                                          <!--                    />-->
                                      <v-textarea
                                          @focus="lockGlobalQueryBc"
                                          @blur="unlockGlobalQueryBc"
                                          label="Description"
                                          outlined
                                          clearable
                                          rows="6"
                                          v-model="variation.description"
                                          append-icon="mdi-share-all"
                                          @click:append="copyToAllVariations(variation, 'description')"
                                      />
                                      <v-text-field v-if="variation.metadata" append-icon="mdi-share-all" @click:append="copyToAllVariations(variation, 'metadata.packagingQuantity')" v-model="variation.metadata.packagingQuantity" type="number" hide-details value="1" outlined dense label="Packaging Quantity"/>
                                      <v-checkbox append-icon="mdi-share-all" @click:append="copyToAllVariations(variation, 'metadata.requireSerials')" :label="`Require ${serialNaming.plural}?`" v-model="variation.metadata.requireSerials"/>
                                    </v-col>
                                    <v-col>
                                      <span class="d-flex flex-row align-baseline"> 
                                        <v-text-field
                                            @focus="lockGlobalQueryBc"
                                            @blur="unlockGlobalQueryBc"
                                            dense
                                            label="Regular Price"
                                            outlined
                                            clearable
                                            type="number"
                                            v-model="variation.regularPrice"
                                            append-icon="mdi-share-all"
                                            @click:append="copyToAllVariations(variation, 'regularPrice')"
                                        />
                                        <span v-if="variation.taxType !== 1" class="d-flex flex-row align-baseline">
                                          <v-btn class="ml-3" x-small fab color="info" @click="addVat('rp', v)">+VAT</v-btn>
                                          <v-btn class="ml-2" x-small fab color="info" @click="removeVat('rp', v)">-VAT</v-btn>
                                        </span>
                                      </span>
                                      <span class="d-flex flex-row align-baseline">
                                        <v-text-field
                                            @focus="lockGlobalQueryBc"
                                            @blur="unlockGlobalQueryBc"
                                            dense
                                            :label="getGlobalValue('VEC_PRODUCT_ALT_PRICE_LABEL')||'Promotion Price (Sale Price)'"
                                            outlined
                                            clearable
                                            style="margin-top: -20px;"
                                            type="number"
                                            v-model="variation.salePrice"
                                            append-icon="mdi-share-all"
                                            @click:append="copyToAllVariations(variation, 'salePrice')"
                                        />
                                        <span v-if="variation.taxType !== 1" class="d-flex flex-row align-baseline">
                                          <v-btn class="ml-3" x-small fab color="info" @click="addVat('pp', v)">+VAT</v-btn>
                                          <v-btn class="ml-2" x-small fab color="info" @click="removeVat('pp', v)">-VAT</v-btn>
                                        </span>
                                      </span>
                                      <span class="d-flex flex-row">
                                        <v-text-field
                                            @focus="lockGlobalQueryBc"
                                            @blur="unlockGlobalQueryBc"
                                            dense
                                            label="Blue Tag Price"
                                            outlined
                                            clearable
                                            style="margin-top: -20px;"
                                            type="number"
                                            v-if="getGlobalValue('VEC_INCLUDES_BLUETAGPRICE')==='true'"
                                            v-model="variation.metadata.blueTagPrice"
                                            append-icon="mdi-share-all"
                                            @click:append="copyToAllVariations(variation, 'metadata.blueTagPrice')"
                                        />
                                      </span>
                                      <span v-if="getGlobalValue('productTaxTypeEditable')==='true' && getGlobalValue('productTaxTypeMinimal')==='true'">
                                        <span>Tax Type</span>
                                        <v-switch
                                            class="mt-0"
                                            v-model="variation.taxType"
                                            color="info"
                                            :label="variation.taxType===1?'Non-VAT':'VAT Inclusive'"
                                            :false-value=1
                                            :true-value=2
                                            append-icon="mdi-share-all"
                                            @click:append="copyToAllVariations(variation, 'taxType')"
                                        />
                                      </span>
                                      <span v-else-if="getGlobalValue('productTaxTypeEditable')==='true'">
                                        <v-select append-icon="mdi-share-all" @click:append="copyToAllVariations(variation, 'taxType')" hide-details label="Tax Type" item-text="name" item-value="id" outlined v-model="variation.taxType" :items="taxTypes"/>
                                      </span>
                                      <span v-if="getGlobalValue('VEC_ADMIN_USE_FOREIGN_CURRENCY')==='true'">
                                        <span class="d-flex flex-row align-center pt-4 pb-2">
                                          <span>Foreign Currencies Regular Price</span>
                                          <v-btn x-small class="ml-2" color="info" @click="addCurrency(variation)"><v-icon>mdi-plus</v-icon> New</v-btn>
                                          <v-btn class="ml-2" x-small color="info" @click="copyToAllVariations(variation, 'metadata.currencyPrices')"><v-icon>mdi-share-all</v-icon></v-btn>
                                        </span>
                                        <span v-if="variation.metadata?.currencyPrices">
                                          <span v-for="(currency, c) of variation.metadata.currencyPrices" :key="c">
                                            <span class="d-flex flex-row align-center pb-1" style="border-bottom: 1px solid #eee;">
                                              <v-text-field outlined dense class="pr-1" hide-details label="Code (USD, JMD, etc)" v-model="currency.code"/>
                                              <v-text-field outlined dense class="pl-1" hide-details label="Value" number v-model="currency.value"/>
                                              <!--                            <v-checkbox dense hide-details label="VAT?" v-model="currency.useVat"/>-->
                                              <v-btn x-small class="ml-1" color="error" @click="removeCurrency(variation, c)" text><v-icon>mdi-close</v-icon></v-btn>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="4">
                              <v-card outlined class="mb-2">
                                <v-card-text>
                                  <v-row>
                                    <v-col>
                                      <v-checkbox
                                          v-model="variation.manageStock"
                                          label="Manage Stock?"
                                          outlined
                                          dense
                                          hint="Enable this to sell products from inventory quantities."
                                          persistent-hint
                                          append-icon="mdi-share-all"
                                          @click:append="copyToAllVariations(variation, 'manageStock')"
                                      />
                                      <v-checkbox
                                          v-model="variation.notifyOnMinQuantity"
                                          label="Notify On Min Quantity?"
                                          outlined
                                          dense
                                          hint="Uncheck to disable notifications when this product reaches it's minimum quantity."
                                          persistent-hint
                                          append-icon="mdi-share-all"
                                          @click:append="copyToAllVariations(variation, 'notifyOnMinQuantity')"
                                      />
                                      <v-text-field
                                          @focus="lockGlobalQueryBc"
                                          @blur="unlockGlobalQueryBc"
                                          dense
                                          class="mt-2"
                                          label="Minimum Notification Quantity"
                                          outlined
                                          clearable
                                          type="number"
                                          :disabled="!variation.notifyOnMinQuantity"
                                          v-model="variation.minQuantity"
                                          append-icon="mdi-share-all"
                                          @click:append="copyToAllVariations(variation, 'minQuantity')"
                                      />
                                      <v-text-field
                                          @focus="lockGlobalQueryBc"
                                          @blur="unlockGlobalQueryBc"
                                          dense
                                          label="Available Quantity"
                                          outlined
                                          clearable
                                          type="number"
                                          style="margin-top: -10px;"
                                          v-model="variation.availableQty"
                                          append-icon="mdi-share-all"
                                          @click:append="copyToAllVariations(variation, 'availableQty')"
                                      />
                                      <v-text-field
                                          @focus="lockGlobalQueryBc"
                                          @blur="unlockGlobalQueryBc"
                                          dense
                                          label="In Stock Quantity"
                                          outlined
                                          clearable
                                          type="number"
                                          style="margin-top: -20px;"
                                          v-model="variation.physicalQty"
                                          append-icon="mdi-share-all"
                                          @click:append="copyToAllVariations(variation, 'physicalQty')"
                                      />
                                      <v-text-field
                                          @focus="lockGlobalQueryBc"
                                          @blur="unlockGlobalQueryBc"
                                          dense
                                          label="Minimum Order Quantity"
                                          hint=""
                                          persistent-hint
                                          outlined
                                          clearable
                                          type="number"
                                          style="margin-top: -20px;"
                                          v-model="variation.metadata.minSaleQuantity"
                                          append-icon="mdi-share-all"
                                          @click:append="copyToAllVariations(variation, 'metadata.minSaleQuantity')"
                                      />
                                    </v-col>
                                    <!--                                <v-col>-->
                                    <!--                                  <h2>E-commerce Options</h2>-->
                                    <!--                                  <v-checkbox label="Visible?" v-model="variation.ecomVisible"/>-->
                                    <!--                                </v-col>-->
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <h3>Suppliers</h3>
                              <span v-if="variation.suppliers.length===0">You need to select the suppliers you want from the Suppliers section above.</span>
                              <span>If this variation does not use a certain supplier, you should uncheck that supplier.</span>
                              <v-card outlined v-for="(supplier, i) in variation.suppliers" :key="i" class="mb-1">
                                <v-card-text class="d-flex flex-row align-center my-0 py-0">
                                  <v-checkbox v-model="supplier.enabled"/>
                                  <span class="col-9"><b v-if="supplier.enabled">{{supplier.name}}</b><span v-else>{{supplier.name}}</span></span>
                                  <v-text-field :disabled="!supplier.enabled" clearable class="mt-2" style="margin-bottom: -20px;" dense type="number" label="Cost Price" outlined v-model="supplier.costPrice"/>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <span>
                <span class="d-flex flex-row align-center mb-1">
                  <h2>Relations</h2>
                  <span class="ml-1 align-center">
                    <v-btn :disabled="loaders.suppliers || unsavedTypeChange" @click="updateRelations" :loading="loaders.relations" v-if="isAllowed('product', 'u')" class="ml-2" color="success" small>Save Changes</v-btn>
                  </span>
                </span>
              </span>
              <v-form :disabled="loaders.relations || unsavedTypeChange">
                <v-row>
                  <v-col>
                    <span>
                      <span class="d-flex flex-row align-center mb-1">
                        <h2>Brand</h2>
                        <span class="ml-1">
                          <v-btn :disabled="loaders.relations || unsavedTypeChange" v-if="isAllowed('brand','c')" @click="createBrandDialog=true" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                        </span>
                      </span>
                      <span>
                        <v-autocomplete
                            clearable
                            v-model="data.brandId"
                            :items="brands"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            label="Brand"
                        />
                      </span>
                    </span>
                    <span>
                      <span class="d-flex flex-row align-center mb-1">
                        <h2>Tags</h2>
                        <span class="ml-1">
                          <v-btn :disabled="loaders.relations || unsavedTypeChange" v-if="isAllowed('productTag','c')" @click="createTagDialog=true" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                        </span>
                      </span>
                      <span>
                        <v-autocomplete
                            v-model="data.tags"
                            item-text="name"
                            item-value="id"
                            :items="tags"
                            label="Tags"
                            multiple
                            outlined
                            dense
                            chips
                            clearable
                            deletable-chips
                        />
                      </span>
                    </span>
                  </v-col>
                  <v-col>
                    <span>
                      <span class="d-flex flex-row align-center mb-1">
                        <h2>Categories</h2>
                        <span class="ml-1">
                          <v-btn :disabled="loaders.relations || unsavedTypeChange" v-if="isAllowed('productCategory','c')" @click="createCategoryDialog=true" fab color="info" x-small><v-icon>mdi-plus</v-icon></v-btn>
                        </span>
                      </span>
                      <span>
                        <v-select clearable label="Categories" v-model="data.categories" :items="categories" :item-text="category => category.familyName+'  (ID:'+category.id+')'" item-value="id" chips deletable-chips multiple outlined/>
                      </span>
                      <!--                    <span>-->
                      <!--                      <v-card outlined>-->
                      <!--                        <v-list style="height: 200px; overflow-y: scroll" class="pt-7 pb-0">-->
                      <!--                          <v-list-item v-for="(category, i) of categories" :key="i" style="margin: 0; margin-top: -20px;">-->
                      <!--                            <v-checkbox :value="category.id" v-model="data.categories" :label="category.familyName+'  (ID:'+category.id+')'" style="margin: 0;"/>-->
                      <!--                          </v-list-item>-->
                      <!--                        </v-list>-->
                      <!--                      </v-card>-->
                      <!--                    </span>-->
                    </span>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="createVariationDialog.isOpen" width="80%">
      <v-card>
        <v-card-title>
          Create Variation
        </v-card-title>
        <v-card-text>
          <span>
            <v-radio-group v-model="createVariationDialog.type" row>
              <v-radio value="single" label="Single Variation"/>
              <v-radio value="combination" label="Generate All Variations"/>
            </v-radio-group>
          </span>
          <span v-if="createVariationDialog.type==='single'" class="red--text">You will select your attributes and terms on the next screen.</span>
          <span v-if="createVariationDialog.type==='combination'">
            <h4>Select the attributes you want.</h4>
            <span class="d-flex flex-row align-center mb-2">
              <v-select clearable :items="attributes" hide-details v-model="createVariationDialog.data.attributes" return-object item-text="name" multiple outlined chips deletable-chips/>
              <v-btn @click="createVariationDialog.data.attributes=attributes" class="ml-2" color="info">Select All Attributes</v-btn>
            </span>
            <span v-for="(attr, i) of createVariationDialog.data.attributes" :key="i" class="d-flex flex-row align-center mb-2">
              <span class="mt-2" style="width: 100px;"><h3>{{attr.name}}</h3></span>
              <v-select hide-details clearable multiple return-object item-text="name" dense chips deletable-chips outlined :items="attr.ProductAttributeTerms" v-model="attr.selectedTerms"/>
              <v-btn @click="selectAllTermsForNewVariation(attr)" class="ml-2" color="info">Select All Terms</v-btn>
            </span>
            <span>
              <span>Any variations you already have that matches any of the combinations will remain as they were and a duplicate will NOT be created.</span> <br>
              <span>If <v-btn disabled x-small>Generate Variations</v-btn> is disabled, it means there are no new variations to create from the options you selected.</span>
            </span>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="closeCreateVariationDialog">Cancel</v-btn>
          <v-btn v-if="createVariationDialog.type==='single'" color="success" @click="createVariation">Create</v-btn>
          <v-btn v-if="createVariationDialog.type==='combination'" :disabled="numPossibleVariations===0" color="success" @click="generateAllVariations">Generate {{ (numPossibleVariations?numPossibleVariations:'') + ' Variations'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="addAttributeToVariationDialog.isOpen" width="600">
      <v-card>
        <v-card-title>
          Add Attribute To Variation
        </v-card-title>
        <v-card-text class="pt-2">
          <span class="d-flex flex-row">
            <v-select class="col-6 mr-1" @change="clearAddAttributeToVariationTerm" v-model="addAttributeToVariationDialog.data.attribute" dense outlined label="Attribute" return-object item-text="name" :items="filterAttributesForAddingToVariation"/>
            <v-select class="col-6 ml-1" v-model="addAttributeToVariationDialog.data.term" dense outlined label="Term" item-text="name" item-value="id" :disabled="!addAttributeToVariationDialog.data.attribute" :items="addAttributeToVariationDialog?.data?.attribute?.ProductAttributeTerms"/>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="closeAddAttributeToVariationDialog">Cancel</v-btn>
          <v-btn color="success" @click="addAttributeToVariation" :disabled="!addAttributeToVariationDialog.data.attribute || !addAttributeToVariationDialog.data.term || !addAttributeToVariationDialog.data.variation">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createSupplierDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Supplier
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" :rules="nameRules" v-model="newSupplier.name" label="Name" outlined></v-text-field>
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" v-model="newSupplier.email" label="Email" outlined></v-text-field>
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" v-model="newSupplier.phone" label="Phone" outlined></v-text-field>
          <div v-if="existingData.length>0">
            <p class="red--text">Similar Suppliers already exist. Are you sure you want to create another?</p>
            <h3>Or Choose A Similar Supplier</h3>
            <v-btn class="mx-1 my-1" color="info" @click="existingSupplierSelected(item)" v-for="(item, i) in existingData" :key="i">{{item.name}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateSupplier">
            Cancel
          </v-btn>
          <v-btn :loading="supplierLoading" v-if="existingData.length===0" :disabled="!newSupplier.name" class="primary" text @click="createSupplier">
            Create
          </v-btn>
          <v-btn v-else :loading="supplierLoading" class="primary" text @click="confirmCreateSupplier">
            Create New
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createBrandDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Brand
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" @input="resetExisting" :rules="nameRules" v-model="newBrand.name" label="Name" outlined></v-text-field>
          <div v-if="existingData.length>0">
            <p class="red--text">Similar Brands already exist. Are you sure you want to create another?</p>
            <h3>Or Choose A Similar Brand</h3>
            <v-btn class="mx-1 my-1" color="info" @click="existingBrandSelected(item)" v-for="(item, i) in existingData" :key="i">{{item.name}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateBrand">
            Cancel
          </v-btn>
          <v-btn :loading="brandLoading" v-if="existingData.length===0" :disabled="!newBrand.name" class="primary" text @click="createBrand">
            Create
          </v-btn>
          <v-btn v-else class="primary" :loading="brandLoading" text @click="confirmCreateBrand">
            Create New
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createCategoryDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Category
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" :rules="nameRules" v-model="newCategory.name" label="Name" outlined></v-text-field>
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" v-model="newCategory.slug" label="Slug (Optional)" outlined></v-text-field>
          <v-select :items="categories" item-text="familyName" item-value="id" v-model="newCategory.parent" label="Parent Category (Optional)" outlined></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateCategory">
            Cancel
          </v-btn>
          <v-btn :loading="categoryLoading" :disabled="!newCategory.name" class="primary" text @click="createCategory">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createTagDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Tag
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" :rules="nameRules" v-model="newTag.name" label="Name" outlined></v-text-field>
          <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc" v-model="newTag.slug" label="Slug (Optional)" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateTag">
            Cancel
          </v-btn>
          <v-btn :loading="tagLoading" :disabled="!newTag.name" class="primary" text @click="createTag">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createAttributeDialog" width="600">
      <v-card>
        <v-card-title class="text-h5">
          Create New Attribute
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-card outlined class="spacedCard">
            <v-text-field @focus="lockGlobalQueryBc" @blur="unlockGlobalQueryBc"
                          label="Name"
                          outlined
                          v-model="newAttribute.name"
            />
            <v-combobox
                multiple
                outlined
                v-model="newAttribute.values"
                label="Values"
                chips
                deletable-chips
                clearable
                persistent-hint
                hint="Press Enter to separate values."
            />
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeNewAttributeDialog">
            Cancel
          </v-btn>
          <v-btn class="success" :disabled="newAttribute.name.length===0" @click="saveAttribute" text :loading="attrLoading">Save Attribute</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createTermsDialog" width="600">
      <v-card>
        <v-card-title class="text-h5">
          Create New Terms
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-card outlined class="spacedCard">
            <v-select outlined label="Attribute" :items="attributes" item-value="id" item-text="name" v-model="newTerms.attribute"/>
            <v-combobox
                multiple
                outlined
                v-model="newTerms.values"
                label="Values"
                chips
                deletable-chips
                clearable
                persistent-hint
                hint="Press Enter to separate values."
            />
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeNewTermsDialog">Cancel</v-btn>
          <v-btn class="success" text :disabled="newTerms.values.length===0" @click="saveAttributeTerms" :loading="termsLoading">Save Terms</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="printDialog.isOpen"
        width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print Barcodes
        </v-card-title>
        <v-card-text>
          <h3 style="margin: 0px;">Select item to print</h3>
          <hr>
          <v-select
              class="mt-2"
              :items="filteredPrinters"
              item-text="name"
              item-value="id"
              label="Select a Printer"
              v-model="printDialog.printer"
              outlined
          ></v-select>
          <v-text-field dense style="margin-top: 10px;" v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
          <authorizer
              v-bind:buttontext="'Print'"
              v-bind:small="true"
              v-on:response="attemptPrint($event)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="imageData.isOpen" width="600">
      <v-card>
        <v-card-title class="text-h5">
          Modify Image
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-card outlined class="spacedCard">
            <v-text-field label="Name (Optional)" v-model="imageData.data.name" outlined dense/>
            <v-autocomplete
                multiple
                outlined
                v-model="imageData.data.tags"
                :items="mediaTags"
                label="Tags"
                chips
                deletable-chips
                clearable
            />
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeImageSettings">Cancel</v-btn>
          <v-btn class="success" @click="updateImage" :loading="imageData.loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="deleteProductDialog.isOpen" width="600">
      <v-card>
        <v-card-title>
          Delete Product
        </v-card-title>
        <v-card-text>
          <span class="red--text">Are you sure you want to delete <b>Product ID:{{deleteProductDialog.product}}?</b></span>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="" @click="closeDeleteProductDialog">Cancel</v-btn>
          <v-btn color="error" :loading="deleteProductDialog.loading" @click="deleteProduct">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="800" v-model="productSerialsDialog.isOpen">
      <v-card v-if="productSerialsDialog.selected">
        <v-card-title>
          <span>{{serialNaming.plural}} for Product #{{productSerialsDialog.selected.id}}</span>
          <v-btn v-if="getGlobalValue('productSerialCreateOnProducts')" fab x-small color="info" class="ml-2" @click="openCreateSerial"><v-icon>mdi-plus</v-icon></v-btn>
          <v-progress-circular class="ml-2" indeterminate color="success" v-if="productSerialsDialog.loading"/>
        </v-card-title>
        <v-card-text>
          <v-text-field append-outer-icon="mdi-magnify" outlined dense v-model="productSerialsDialog.search" clearable/>
          <v-data-table
              :items="productSerialsDialog.data"
              :headers="productSerialsDialog.headers"
              class="elevation-0"
              :search="productSerialsDialog.search"
          >
            <template v-slot:item.locationId="{ item }">
              <span>{{lookupLocation(item.locationId) || 'None'}}</span>
            </template>
            <template v-slot:item.customerId="{ item }">
              <span>{{item.customerId?`Customer ${item.customerId}`:'None'}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <span class="d-flex flex-row">
                <v-btn color="error" x-small :disabled="productSerialsDialog.loading" fab @click="deleteSerial(item)"><v-icon>mdi-delete</v-icon></v-btn>
                <v-btn class="ml-2" color="success" x-small :disabled="productSerialsDialog.loading" fab @click="getSerialHistory(item)"><v-icon>mdi-history</v-icon></v-btn>
              </span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span>{{utils.formatDate(item.createdAt, 'withTime')}}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="productSerialsDialog.createOpen" width="500">
      <v-card v-if="productSerialsDialog.selected">
        <v-card-title>Create {{serialNaming.singular}} for Product #{{productSerialsDialog.selected.id}}</v-card-title>
        <v-card-text>
          <v-text-field dense outlined v-model="productSerialsDialog.create" :label="serialNaming.singular"/>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="createSerial" :disabled="!productSerialsDialog.create||productSerialsDialog.create.length===0||productSerialsDialog.create.trim().length===0" :loading="productSerialsDialog.createLoading" color="success">Create</v-btn>
          <v-btn @click="closeCreateSerial" color="error">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<style scoped>
.spacedCard{
  padding: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import axios from 'axios';
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex'
import utils from "../../plugins/helpers";
import authorizer from '../../components/authorizer.vue'
export default {
  components: {
    authorizer,
  },
  data () {
    return {
      utils: utils,
      printers: [],
      filteredPrinters: [],
      documentTypes: [],
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: true,
      loader: false,
      max25chars: v => v.length <= 25 || 'Input too long!',
      data: {
        taxType: 1,
        notifyOnMinQuantity: true,
        minQuantity: 0,
        availableQty: 0,
        physicalQty: 0,
        regularPrice: 0,
        salePrice: 0,
        manageStock: true,
        attributes: [],
        variations: [],
        suppliers: [],
        brandId: null,
        tags: [],
        categories: [],
        metadata: {
          requireSerials: false,
          minSaleQuantity: 1,
          allowFractional: false,
          unit: "unit",
          quantityStepAmount: 1
        }
      },
      productTypeValues: [
        {name: "Simple", value: "simple"}, {name: "Variable", value: "variable"}
      ],
      quantityChangeTypes: [
        {name: "Incorrect Quantity", value: "absolute"}, {name: "Adjusting Quantity", value: "relative"}, {name: "No longer using temporary quantity", value: "tempQuantity"}
      ],

      prevType: null,

      loaders: {
        general: false,
        suppliers: false,
        attributes: false,
        variations: false,
        relations: false
      },

      categories: [],
      tags: [],
      attributes: [],
      brands: [],
      suppliers: [],
      taxTypes: [],

      deleteProductDialog: {
        isOpen: false,
        loading: false,
        product: null
      },

      createVariationDialog: {
        isOpen: false,
        loading: false,
        type: "single",
        data: {
          attributes: []
        }
      },

      showUnits: false,
      unitTypes: [],

      addAttributeToVariationDialog: {
        isOpen: false,
        loading: false,
        data: {
          variation: null,
          attribute: null,
          term: null
        }
      },

      createSupplierDialog: false,
      newSupplier: {},
      supplierLoading: false,

      createBrandDialog: false,
      brandLoading: false,
      newBrand: {},

      createCategoryDialog: false,
      categoryLoading: false,
      newCategory: {},

      createTagDialog: false,
      tagLoading: false,
      newTag: {},

      createAttributeDialog: false,
      newAttribute: {
        name: "",
        values: []
      },
      attrLoading: false,

      createTermsDialog: false,
      newTerms: {
        attribute: null,
        values: []
      },
      termsLoading: false,

      nameRules: [
        v => !!v || 'Name is required'
      ],

      existingData: [],

      imageData: {
        isOpen: false,
        data: {tags: [], type: 'image', name: ''},
        product: null,
        loading: false,
        img: null
      },

      mediaTags: [],

      printDialog: {
        isOpen: false,
        quantity: 1,
        jobType: 'productLabel',
        deliveryNote: '',
        printer: '',
      },
      registerSKUBarcodeDialog: {
        isOpen: false,
        value: "",
        loading: false,
      },

      lowStockQty: 0,

      productSerialsDialog: {
        isOpen: false,
        data: null,
        selected: null,
        loading: false,
        headers: [
          { text: 'ID', align: 'start', value: 'id' },
          { text: 'Serial', value: 'serial', sortable: true},
          { text: 'Branch', value: 'locationId', sortable: true},
          { text: 'Customer', value: 'customerId', sortable: true},
          { text: 'Created', value: 'createdAt', sortable: true},
          { text: 'Actions', value: 'actions', sortable: false},
        ],
        search: "",
        create: '',
        createLoading: false,
        createOpen: false
      },
      loadingSerials: false,
    }
  },
  async mounted(){
    window.scrollTo({top: 0});
    await this.getAllData();
  },
  async created(){
    if(this.getGlobalValue('VEC_SIMPLE_PRODUCTS_ONLY')==='true'){
      this.productTypeValues = this.productTypeValues.filter(x => x.value==='simple');
      this.data.variations = [];
    }

    if(!isNaN(parseInt(this.getGlobalValue('LOW_STOCK_THRESHOLD')))){
      this.lowStockQty = parseInt(this.getGlobalValue('LOW_STOCK_THRESHOLD'))
    }

    let units = this.getGlobalValue('unitTypesData');
    if(units){
      this.unitTypes = units || [];
    }

    this.data.minQuantity = this.lowStockQty

    if(this.getGlobalValue('defaultFactorOfQuantity')){
      this.data.metadata.quantityStepAmount = utils.pff(this.getGlobalValue('defaultFactorOfQuantity'));
    }

    if(this.getGlobalValue('defaultAllowFractional')){
      this.data.metadata.allowFractional = this.getGlobalValue('defaultAllowFractional')==='true';
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', "isAllowed", 'getUser', 'getGlobalValue', 'scanBus', 'lookupUsername', 'lookupLocation', 'serialNaming', ]),
    filterAttributesForAddingToVariation(){
      if(!this.addAttributeToVariationDialog.data.variation) return this.attributes;
      return this.attributes.filter(x => this.addAttributeToVariationDialog.data.variation.attributes.findIndex(y => y.id===x.id)<0)
    },
    numPossibleVariations(){
      if(this.createVariationDialog.data.attributes.length===0) return 0;
      return this.createVariationDialog.data.attributes.reduce((total, attr) => total * (attr.selectedTerms?.length || 0), 1)
    },
    unsavedTypeChange(){
      return this.data.type!==this.prevType;
    }
  },
  methods: {
    ...mapMutations([
      'lockGlobalQueryBc',
      'unlockGlobalQueryBc',
      'setScanBus',
      'resetScanBus',
    ]),
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    addVat(item, index){
      let taxRate = parseFloat(this.getGlobalValue('VEC_VAT_RATE'))
      if(index < 0){ // this implies it was called for simple product
        if(item === 'rp'){
          this.data.regularPrice = utils.pff2((1+taxRate)*this.data.regularPrice)
        }
        if(item === 'pp'){
          this.data.salePrice = utils.pff2((1+taxRate)*this.data.salePrice)
        }
      }else {
        if(item === 'rp'){
          let val = this.data.variations[index]
          val.regularPrice = utils.pff2((1+taxRate)*val.regularPrice)
          this.data.variations.splice(index, 1, val)
        }
        if(item === 'pp'){
          let val = this.data.variations[index]
          val.salePrice = utils.pff2((1+taxRate)*val.salePrice)
          this.data.variations.splice(index, 1, val)
        }
      }
    },
    removeVat(item, index){
      let taxRate = parseFloat(this.getGlobalValue('VEC_VAT_RATE'))
      if(index < 0){ // this implies it was called for simple product
        if(item === 'rp'){
          this.data.regularPrice = utils.pff2(this.data.regularPrice/(1+taxRate))
        }
        if(item === 'pp'){
          this.data.salePrice = utils.pff2(this.data.salePrice/(1+taxRate))
        }
      } else {
        if(item === 'rp'){
          let val = this.data.variations[index]
          val.regularPrice = utils.pff2(val.regularPrice/(1+taxRate))
          this.data.variations.splice(index, 1, val)
        }
        if(item === 'pp'){
          let val = this.data.variations[index]
          val.salePrice = utils.pff2(val.salePrice/(1+taxRate))
          this.data.variations.splice(index, 1, val)
        }
      }
    },
    checkAndUpdateManagedProduct(){
      if(this.data.metadata.requireSerials){
        this.data.manageStock = true
        this.$forceUpdate()
      }
    },
    checkAndUpdateRequireSerials(){
      if(!this.data.manageStock){
        this.data.metadata.requireSerials = false
        this.$forceUpdate()
      }
    },
    async getSerialHistory(item){
     await this.$router.push({path: `/products/serial/${item.serial}`})
    }, 
    async printDirect(type, metadata){
      try {
        let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
        if(x.data.error) throw x.data.error
        console.log(x.data)
        let printWindow = open("","Printing")
        printWindow.document.write(x.data.data.job.htmlToPrint)
        printWindow.setTimeout(()=>{
          printWindow.print()
          printWindow.close()
        },300)
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
    }, 
    lockScanBusForSKUBC(){
      this.setScanBus({handler: "SKU"})
    },
    unlockScanBusForSKUBC(){
      this.resetScanBus()
    },
    trimBC(prod){
      if(!prod.sku) return
      prod.sku = prod.sku.replace(/\\\/\/\\=/,'')
    },

    async updateGeneral(){
      try {
        this.loaders.general = true;

        let obj = {
          id: this.data.id,
          name: this.data.name,
          sku: this.data.sku,
          type: this.data.type,
          description: this.data.description,
          regularPrice: parseFloat(this.data.regularPrice),
          salePrice: parseFloat(this.data.salePrice),
          taxType: this.data.taxType,
          manageStock: this.data.manageStock,
          notifyOnMinQuantity: this.data.notifyOnMinQuantity,
          minQuantity: this.data.minQuantity,
          availableQty: this.data.availableQty,
          physicalQty: this.data.physicalQty,
          ecomVisible: this.data.ecomVisible,
          metadata: this.data.metadata
        }

        if(obj.type==='variable'){
          obj = {
            ...obj,
            sku: "",
            regularPrice: 0,
            salePrice: 0,
            taxType: 1,
            manageStock: true,
            notifyOnMinQuantity: true,
            minQuantity: this.lowStockQuantity,
            availableQty: 0,
            physicalQty: 0,
            ecomVisible: false
          }

          if(!isNaN(parseInt(this.getGlobalValue('productTaxTypeDefaultValue')))){
            obj.taxType = parseInt(this.getGlobalValue('productTaxTypeDefaultValue'))
          }
        }

        if(obj.type==="simple"){
          if(!obj.metadata.minSaleQuantity || obj.metadata.minSaleQuantity<=0){
            throw "Minimum Order Quantity must be more than 0."
          }
        }

        let res = await axios.put(`${this.getEndpoint}/api/products/updateGeneral`, obj)
        if(res.data.error) throw res.data.error
        await this.getAllData();

        this.snack("Product Updated", "success");
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loaders.general = false;
      }
    },

    async updateSuppliers(){
      try {
        this.loaders.suppliers = true;
        this.loaders.variations = true;

        let obj = {
          id: this.data.id,
          suppliers: this.data.suppliers,
          variations: this.data.variations
        }

        for(let supplier of obj.suppliers){
          supplier.costPrice = supplier.costPrice?parseFloat(supplier.costPrice):0
        }

        for(let variation of obj.variations){
          for(let supplier of variation.suppliers){
            supplier.costPrice = supplier.costPrice?parseFloat(supplier.costPrice):0
          }
        }

        console.log(obj)

        let res = await axios.put(`${this.getEndpoint}/api/products/updateSuppliers`, obj)
        if(res.data.error) throw res.data.error
        await this.getAllData();

        this.snack("Suppliers Updated", "success");
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loaders.suppliers = false;
        this.loaders.variations = false;
      }
    },

    async updateVariations(){
      try {
        this.loaders.variations = true;

        let obj = {
          id: this.data.id,
          variations: this.data.variations,
          metadata: {
            unit: this.data.metadata.unit,
            quantityStepAmount: this.data.metadata.quantityStepAmount,
            allowFractional: this.data.metadata.allowFractional
          }
        }

        for(let variation of obj.variations){
          if(!variation.metadata.minSaleQuantity || variation.metadata.minSaleQuantity<=0){
            throw `Minimum Order Quantity must be more than 0. (See variation: ${variation.name})`
          }

          for(let supplier of variation.suppliers){
            supplier.costPrice = supplier.costPrice?parseFloat(supplier.costPrice):0
          }
        }

        let res = await axios.put(`${this.getEndpoint}/api/products/updateVariations`, obj)
        if(res.data.error) throw res.data.error
        await this.getAllData();

        this.snack("Variations Updated", "success");
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loaders.suppliers = false;
        this.loaders.variations = false;
      }
    },

    async updateRelations(){
      try {
        this.loaders.relations = true;

        let obj = {
          id: this.data.id,
          brand: this.data.brandId,
          tags: this.data.tags,
          categories: this.data.categories
        }

        console.log(obj)

        let res = await axios.put(`${this.getEndpoint}/api/products/updateRelations`, obj)
        if(res.data.error) throw res.data.error
        await this.getAllData();

        this.snack("Relations Updated", "success");
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loaders.relations = false;
      }
    },

    getSupplierName(id){
      let a = this.suppliers.find(x => x.id===id);
      if(!a) return ''
      return a.name
    },
    getAttributeName(attr){
      let a = this.attributes.find(x => x.id===attr);
      if(!a) return ''
      return a.name
    },
    getTermName(attr, term){
      let a = this.attributes.find(x => x.id===attr);
      if(!a) return '';

      let t = a.ProductAttributeTerms.find(x => x.id===term);

      if(!t) return ''
      return t.name
    },
    updateVariationNames(){
      for(let variation of this.data.variations){
        this.getCustomVariationName(variation)
      }
    },
    getCustomVariationName(v){
      let name = v.name || "No Custom Name";
      if(v && !v.metadata?.customName){
        name = this.data.name || 'Please set the name on the parent Product or use Custom Name';

        let terms = [];
        let attrs = v.attributes;
        for(let attr of attrs){
          terms.push(this.getTermName(attr.id, attr.term))
        }

        let concat = " ("
        if(terms.length>0){
          concat+=terms.join(", ")+")";
        }
        else{
          concat+="No Attributes)"
        }
        name+=concat;
      }

      v.name = name;
      this.$forceUpdate()
      return name
    },

    openCreateVariationDialog(){
      this.createVariationDialog.isOpen = true;
    },
    closeCreateVariationDialog(){
      this.createVariationDialog = {
        isOpen: false,
        loading: false,
        type: "single",
        data: {
          attributes: []
        }
      };
    },
    generateAllVariations(){
      if(!this.createVariationDialog.data.attributes || this.createVariationDialog.data.attributes.length===0) return;

      let variations = [];

      let combinations = this.generateCombinations(this.createVariationDialog.data.attributes)

      for(let combo of combinations){
        let variation = this.generateVariation();

        variation.attributes = combo;

        variations.push(variation);
      }

      for(let variation of variations){
        if(this.data.variations.length>0){
          let exists = false;
          for(let existing of this.data.variations){
            if(variation.attributes.length!==existing.attributes.length) continue;

            let allAttributesSame = true;
            for(let attr of variation.attributes){
              let found = existing.attributes.find(x => x.id===attr.id && x.term===attr.term);
              if(!found){
                allAttributesSame = false;
                break;
              }
            }

            if(allAttributesSame){
              exists = true;
            }
          }

          if(!exists){
            this.data.variations.push(variation)
          }
        }
        else{
          this.data.variations.push(variation)
        }
      }

      this.closeCreateVariationDialog();
    },
    generateCombinations(attrs) {
      if (attrs.length === 0) {
        return [[]];
      }

      const firstAttr = attrs[0];
      const remainingAttrs = attrs.slice(1);
      const subCombinations = this.generateCombinations(remainingAttrs);

      const combinations = [];
      for (let term of firstAttr.selectedTerms) {
        for (let prevTerms of subCombinations) {
          combinations.push([{id: firstAttr.id, term: term.id}].concat(prevTerms));
        }
      }

      return combinations;
    },
    generateVariation(){
      let variation = {
        type: "variation",
        taxType: 1,
        notifyOnMinQuantity: true,
        minQuantity: this.lowStockQty,
        availableQty: 0,
        physicalQty: 0,
        regularPrice: 0,
        salePrice: 0,
        manageStock: true,
        attributes: [],
        suppliers: this.generateSuppliersForVariation(),
        metadata: {
          customName: false,
          requireSerials: false,
          minSaleQuantity: 1
        }
      }

      if(!isNaN(parseInt(this.getGlobalValue('productTaxTypeDefaultValue')))){
        variation.taxType = parseInt(this.getGlobalValue('productTaxTypeDefaultValue'))
      }

      return variation
    },
    createVariation(){
      this.data.variations.push(this.generateVariation())

      this.closeCreateVariationDialog()
    },
    removeVariation(i){
      if(this.data.variations[i].id){
        this.openDeleteProductDialog(this.data.variations[i].id);
      }
      else{
        this.data.variations.splice(i, 1)
      }
    },

    openDeleteProductDialog(id){
      this.deleteProductDialog.product = id;
      this.deleteProductDialog.isOpen = true;
    },
    closeDeleteProductDialog(){
      this.deleteProductDialog.isOpen = false;
      this.deleteProductDialog.product = null;
    },

    openAddAttributeToVariationDialog(v){
      this.addAttributeToVariationDialog.data.variation = v;
      this.addAttributeToVariationDialog.isOpen = true;
    },
    closeAddAttributeToVariationDialog(){
      this.addAttributeToVariationDialog.isOpen = false;
      this.addAttributeToVariationDialog.data.variation = null;
    },
    addAttributeToVariation(){
      this.addAttributeToVariationDialog.data?.variation?.attributes.push({
        id: this.addAttributeToVariationDialog.data.attribute.id,
        term: this.addAttributeToVariationDialog.data.term
      });

      this.getCustomVariationName(this.addAttributeToVariationDialog.data?.variation)

      this.closeAddAttributeToVariationDialog();

      this.addAttributeToVariationDialog = {
        isOpen: false,
        loading: false,
        data: {
          variation: null,
          attribute: null,
          term: null
        }
      }
    },
    clearAddAttributeToVariationTerm(){
      this.addAttributeToVariationDialog.data.term = null;
    },
    removeAttributeFromVariation(v, a){
      this.data.variations[v]?.attributes.splice(a, 1);

      this.getCustomVariationName(this.data.variations[v])
    },
    moveVariationAttributeUp(i, variation){
      if(i<=0) return;

      [variation.attributes[i-1], variation.attributes[i]] = [variation.attributes[i], variation.attributes[i-1]]

      this.getCustomVariationName(variation)
    },
    moveVariationAttributeDown(i, variation){
      if(i>=variation.attributes.length-1) return;

      [variation.attributes[i+1], variation.attributes[i]] = [variation.attributes[i], variation.attributes[i+1]]

      this.getCustomVariationName(variation)
    },

    generateSuppliersForVariation(){
      let sups = []
      for(let supplier of this.data.suppliers){
        sups.push({
          ...supplier,
          costPrice: supplier.costPrice || 0,
          enabled: true
        });
      }

      return sups;
    },
    updateVariationSuppliers(){
      for(let variation of this.data.variations){
        variation.suppliers = this.generateSuppliersForVariation();
      }
    },
    copyCostPriceToVariations(supplier){
      for(let variation of this.data.variations){
        let s = variation.suppliers.find(x => x.id===supplier.id);
        if(!s) continue;

        s.costPrice = supplier.costPrice;
      }
    },

    openNewAttributeDialog(){
      this.createAttributeDialog = true;
    },
    closeNewAttributeDialog(){
      this.createAttributeDialog = false;
      this.newAttribute = {
        name: "",
        values: []
      }
    },
    async saveAttribute(){
      try{
        this.attrLoading = true;

        let res = await axios.post(`${this.getEndpoint}/api/productattributes/addAttributeWithTerms`, this.newAttribute);
        if(res.data.error) throw res.data.error;

        this.attributes.push(res.data.data);

        this.snack("Attribute created.", "success")

        this.closeNewAttributeDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.attrLoading = false;
      }
    },
    closeNewTermsDialog(){
      this.createTermsDialog = false;
      this.newTerms = {
        attribute: null,
        values: []
      }
    },
    async saveAttributeTerms(){
      try{
        this.termsLoading = true;

        let attr = this.attributes.find(x => x.id===this.newTerms.attribute);
        if(!attr) throw "Invalid attribute selected.";

        for(let newTerm of this.newTerms.values){
          let existingTerm = attr.ProductAttributeTerms.find(term => {
            return term.name.toLowerCase()===newTerm.toLowerCase();
          });

          if(existingTerm) throw `The term '${existingTerm.name}' already exists in '${attr.name}'`;
        }

        let createdTerms = await axios.post(`${this.getEndpoint}/api/productattributes/addTermsToAttribute/${attr.id}`, {terms: this.newTerms.values});
        if(createdTerms.data.error) throw createdTerms.data.error

        attr.ProductAttributeTerms = attr.ProductAttributeTerms.concat(createdTerms.data.data);

        this.closeNewTermsDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.termsLoading = false;
      }
    },
    async getAllData(){
      try {
        this.loader = true;

        let res = await axios.get(`${this.getEndpoint}/api/products/externaldata`);
        if(res.data.error) throw res.data.error

        this.attributes = res.data.data.attributes;
        this.brands = res.data.data.brands;
        this.categories = res.data.data.categories;

        this.suppliers = res.data.data.suppliers;
        this.tags = res.data.data.tags;

        if(this.getGlobalValue('VEC_SIMPLE_PRODUCTS_ONLY')==='true'){
          this.productTypeValues = this.productTypeValues.filter(x => x.value==='simple');
          this.data.variations = [];
        }

        res = await axios.get(`${this.getEndpoint}/api/taxTypes`);
        if(res.data.error) throw res.data.error
        this.taxTypes = res.data.data;

        res = await axios.get(`${this.getEndpoint}/api/products/${this.$route.params.id}`);
        if(res.data.error) throw res.data.error

        this.data = res.data.data;

        if(!this.data.metadata?.minSaleQuantity){
          this.data.metadata.minSaleQuantity = 1;
        }

        for(let variation of this.data.variations){
          if(!variation.metadata?.minSaleQuantity){
            variation.metadata.minSaleQuantity = 1;
          }
        }

        this.prevType = this.data.type;

      } catch (error) {
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.loader = false;
      }
    },
    closeCreateSupplier(){
      this.createSupplierDialog = false;
      this.newSupplier = {};
      this.supplierLoading = false;
      this.existingData = [];
    },
    async createSupplier(){
      try{
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers`, this.newSupplier)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.snack("Supplier Created", "success");
          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data;
          this.data.suppliers.push({id:res.data.data.data.id, name: res.data.data.data.name, costPrice: 0.00})
          this.updateVariationSuppliers();
          this.closeCreateSupplier();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Suppliers Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.supplierLoading = false;

      }
    },
    existingSupplierSelected(supplier){
      this.data.suppliers.push(supplier.id)
      this.closeCreateSupplier();
    },
    async confirmCreateSupplier(){
      try {
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers/confirmCreate`, this.newSupplier)
        if(res.data.error) throw res.data.error

        this.snack("Supplier Created", "success");
        let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
        if(suppliers.data.error) throw suppliers.data.error
        this.suppliers = suppliers.data.data;
        this.data.suppliers.push({id:res.data.data.data.id, name: res.data.data.data.name, costPrice: 0.00})
        this.updateVariationSuppliers();
        this.closeCreateSupplier();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.supplierLoading = false;
      }
    },

    closeCreateBrand(){
      this.createBrandDialog = false;
      this.newBrand = {};
      this.brandLoading = false;
      this.existingData = [];
    },
    async createBrand(){
      try{
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands`, this.newBrand)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.snack("Brand Created", "success");
          let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
          if(brands.data.error) throw brands.data.error
          this.brands = brands.data.data;
          this.data.brandId = res.data.data.data.id;
          this.closeCreateBrand();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Brands Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },
    existingBrandSelected(brand){
      this.data.brandId = brand.id;
      this.closeCreateBrand();
    },
    async confirmCreateBrand(){
      try {
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands/confirmCreate`, this.newBrand)
        if(res.data.error) throw res.data.error

        this.snack("Brand Created", "success");
        let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
        if(brands.data.error) throw brands.data.error
        this.brands = brands.data.data;
        this.data.brandId = res.data.data.data.id;
        this.closeCreateBrand();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },

    resetExisting(){
      this.existingData = [];
    },

    closeCreateCategory(){
      this.createCategoryDialog = false;
      this.newCategory = {};
      this.categoryLoading = false;
    },
    async createCategory(){
      try{
        this.categoryLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/productcategories`, this.newCategory)
        if(res.data.error) throw res.data.error

        this.snack("Category Created", "success");
        this.categories.push(res.data.data);
        this.data.categories.push(res.data.data?.id);
        this.closeCreateCategory();
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.categoryLoading = false;
      }
    },

    closeCreateTag(){
      this.createTagDialog = false;
      this.newTag = {};
      this.tagLoading = false;
    },
    async createTag(){
      try{
        this.tagLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/producttags`, this.newTag)
        if(res.data.error) throw res.data.error

        this.snack("Tag Created", "success");
        this.tags.push(res.data.data.data);
        this.data.tags.push(res.data.data.data.id);
        this.closeCreateTag();
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error.msg?.message || error, "error");
      }
      finally {
        this.tagLoading = false;
      }
    },

    async deleteProduct(){
      try {
        this.deleteProductDialog.loading = true

        if(!this.deleteProductDialog.product) throw "Missing product to delete."

        let res = await axios.delete(`${this.getEndpoint}/api/products/${this.deleteProductDialog.product}`)
        if(res.data.error) throw res.data.error

        this.snack("Product Deleted", "success");

        if(this.deleteProductDialog.product==this.$route.params.id){
          await this.$router.go(-1);
        }
        else{
          await this.getAllData();
          this.closeDeleteProductDialog();
        }
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      } finally {
        this.deleteProductDialog.loading = false
      }
    },
    openPrintDialog(){
      this.printDialog.isOpen = true
    },
    closePrintDialog(){
      this.printDialog = {
        isOpen: false,
        quantity: 1,
        jobType: '',
        deliveryNote: '',
        printer: ''
      }
    },
    async attemptPrint(e){
      try {
          if(!e.pin&&!e.vsidToken) throw "Error Getting Credentials"
          e.metadata = this.printDialog
          let res = await axios.post(`${this.getEndpoint}/api/print/productLabel/${this.$route.params.id}`, e)
          if(res.data.error) throw res.data.error
          this.closePrintDialog()
      } catch (error) {
        console.log(error)
        this.snack(error)
        this.closePrintDialog()
      }
    },
    async uploadImage(file, product){
      try {
        if(!product.id) throw "Product ID is missing."
        this.loader = true;
        this.imgToUpload = file
        let theFile = new FormData()
        theFile.append('image', this.imgToUpload, "image")
        let res = await axios.post(`${this.getEndpoint}/api/products/uploadImages/${product.id}`, theFile)
        if(res.data.success){
          this.snack("✅ Image Updated.", "success")
          product.metadata.media = res.data.data;
          file = null;
        }else throw res.data.error
      } catch (error) {
          console.log(error)
          this.snackText = error
          this.snackBar = true;        
      } finally {
        this.loader = false
        this.imgToUpload = null
      }
    },
    async deleteImage(img, product){
      try {
        if(!product.id) throw "Product ID is missing."
        this.loader = true;

        let verify = confirm("Are you sure you want to delete this image? This action cannot be reversed.");
        if(!verify) return;

        let res = await axios.post(`${this.getEndpoint}/api/products/deleteImage`, {url: img.url, pId: product.id})
        if(res.data.success){
          this.snack("✅ Image Deleted.", "success")
          product.metadata.media = res.data.data;
        }
        else 
          throw "Error: Image was not deleted."
      } catch (error) {
        this.snackText = error
        this.snackBar = true;
      } finally {
        this.loader = false
      }
    },
    async updateImage(){
      try {
        this.imageData.loading = true;
        if(!this.imageData?.product?.id) throw "Product ID is missing."
        if(!this.imageData?.img) throw "Image Data is missing."
        if(!this.imageData?.data) throw "Image New Data is missing."

        let res = await axios.put(`${this.getEndpoint}/api/products/updateImage`, {url: this.imageData.img.url, pId: this.imageData.product.id, data: this.imageData.data})
        if(res.data.error) throw res.data.error;

        this.snack("✅ Image Updated.", "success");
        this.imageData.product.metadata.media = res.data.data;

      } catch (error) {
        console.log(error)
        this.snackText = error
        this.snackBar = true;
      } finally {
        this.imageData.loading = false;
        this.closeImageSettings();
      }
    },
    openImageSettings(img, product){
      this.imageData.data = {tags: img.tags, type: img.type, name: img.data.name || ''};
      this.imageData.isOpen = true;
      this.imageData.img = img;
      this.imageData.product = product;
    },
    closeImageSettings(){
      this.imageData.isOpen = false;
      this.imageData.data = {tags: [], type: 'image', name: ''};
      this.imageData.img = null;
      this.imageData.product = null;
    },
    openImage(url){
      window.open(url, '_blank').focus();
    },
    async viewSerials(){
      try {
        this.loadingSerials = true;

        await this.getSerials();

        this.productSerialsDialog.isOpen = true;

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.loadingSerials = false;
      }
    },
    closeSerialDialog(){
      this.productSerialsDialog = {
        ...this.productSerialsDialog,
        isOpen: false,
        data: null,
        selected: null
      };
    },
    async createSerial(){
      try {
        this.productSerialsDialog.createLoading = true;

        let obj = {
          serial: this.productSerialsDialog.create.trim()
        }

        let x = await axios.post(`${this.getEndpoint}/api/products/${this.productSerialsDialog.selected.id}/serials`, obj)
        if(x.data.error) throw x.data.error

        this.productSerialsDialog.data.push(x.data.data);

        this.closeCreateSerial();

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.productSerialsDialog.createLoading = false;
      }
    },
    openCreateSerial(){
      this.productSerialsDialog.createOpen = true;
    },
    closeCreateSerial(){
      this.productSerialsDialog.createOpen = false;
      this.productSerialsDialog.create = '';
    },
    async getSerials(){
      try{
        this.productSerialsDialog.selected = this.data;

        let x = await axios.get(`${this.getEndpoint}/api/products/${this.data.id}/serials`)
        if(x.data.error) throw x.data.error
        this.productSerialsDialog.data = x.data.data;
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      }
    },
    async deleteSerial(serial){
      try {
        this.productSerialsDialog.loading = true;

        let x = await axios.delete(`${this.getEndpoint}/api/products/${this.productSerialsDialog.selected.id}/serials/${serial.id}`)
        if(x.data.error) throw x.data.error

        await this.getSerials()

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error")
      } finally {
        this.productSerialsDialog.loading = false;
      }
    },
    addCurrency(prod){
      if(!prod.metadata?.currencyPrices){
        prod.metadata.currencyPrices = [];
      }

      //let useVat = prod.taxType!==1;

      prod.metadata.currencyPrices.push({
        code: '',
        value: 0,
        //useVat: useVat
      });

      this.$forceUpdate();
    },
    removeCurrency(prod, i){
      prod.metadata.currencyPrices.splice(i, 1);
      this.$forceUpdate();
    },
    copyToAllVariations(variation, value){
      let val = _.get(variation, value);
      for(let v of this.data.variations){
        _.set(v, value, val);
      }
      this.$forceUpdate();
    },
    selectAllTermsForNewVariation(attr){
      attr.selectedTerms=attr.ProductAttributeTerms;
      this.$forceUpdate();
    }
  }
}
</script>